import styled from "styled-components/macro";
import Pagination from '@mui/material/Pagination';

const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: auto;
    align-items: center;

`;

const LeftContainer = styled.div`
    display: inline-flex;
`

const RightContainer = styled.div``

const Title = styled.span`
    font-size: 20pt;
    font-weight: 600;
    margin-right: 30px;
`

export const SubHeader = ({ title, children, pagination, setPagination }) => {

    const handleChange = (event, value) => {
        setPagination({...pagination, currentPage: value});
    };
    return <Container>
        <LeftContainer>
            <Title>{title}</Title>
            {children}
        </LeftContainer>
        <RightContainer>
            {<Pagination count={pagination.totalPages} page={pagination.currentPage} onChange={handleChange} size="large" />}
        </RightContainer>
    </Container>
}