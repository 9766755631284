import React from 'react'
import { CircularProgress } from '@material-ui/core'

export default function Loading() {
    const divStyles = () => {
        return {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            minHeight: "20rem"
        }
    }

    const progressCircleStyles = () => {
        return {
            color: "#A5D65D"
        }
    }

    return (
        <div style={divStyles()}>
            <CircularProgress style={progressCircleStyles()} size={50} className="loadingCircle" />
        </div>
    )
}
