import React from 'react'
import styled from 'styled-components/macro'
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { useEffect } from 'react';
import axios from 'axios';
import { useState } from 'react';
import { useContext } from 'react';
import { AppContext } from '../../../../contexts/AppContext';
import Loading from '../../../../components/common/Loading';

const Wrapper = styled.div`
`

export default function OpcSubUsagesTable({ setTab, setPagination, pagination }) {

    const { appState, setAppState } = useContext(AppContext);
    const [data, setData] = useState([])


    const apiUrl = process.env.REACT_APP_OPC_API_ENDPOINT;
    // const apiUrl = 'http://localhost:3030';
    // const apiUrl = 'https://opc-api-xeu74yuiwq-ez.a.run.app'

    useEffect(() => {
        setTab(1)
        try {
            getSubUsages(pagination.currentPage)
        } catch (e) {
            console.error(e)
        }
    }, [pagination.currentPage])

    const getSubUsages = async (currentPage) => {
        const options = {

        }
        const response = await axios.post(`${apiUrl}/api/subusages/pages`, {
            page: currentPage - 1,
            size: 100
        }, {
            headers: {
                authorization: appState.user.accessToken,
                refresh_token: appState.user.refreshToken
            }
        })
        if (response.headers.access_token && response.headers.refresh_token) {
            setAppState({ ...appState, user: { ...appState.user, accessToken: response.headers.access_token, refreshToken: response.headers.refresh_token } })
        }
        setData(response.data.subUsages)
        setPagination({ totalPages: response.data.totalPages, currentPage: currentPage })
    }

    const dateFilterParams = {
        // provide comparator function
        comparator: (filterLocalDateAtMidnight, cellValue) => {
            const dateAsString = cellValue;

            if (dateAsString == null) {
                return 0;
            }

            // In the example application, dates are stored as dd/mm/yyyy
            // We create a Date object for comparison against the filter date

            const cellDate = new Date(dateAsString);

            // Now that both parameters are Date objects, we can compare
            if (cellDate < filterLocalDateAtMidnight) {
                return -1;
            } else if (cellDate > filterLocalDateAtMidnight) {
                return 1;
            }
            return 0;
        }
    };

    const getDate = params => {
        const dateString = params.value
        const year = dateString.substr(0, 4)
        const month = dateString.substr(5, 2)
        const day = dateString.substr(8, 2)
        const hour = dateString.substr(11, 2)
        const min = dateString.substr(14, 2)
        const sec = dateString.substr(17, 2)

        // console.log(`${year}-${month}-${day} ${hour}:${min}:${sec}`)
        return `${year}-${month}-${day} ${hour}:${min}:${sec}`
    }

    const defaultColDef = {
        filter: true,
        resizable: true,
        sortable: true
    }


    return (
        <Wrapper>
            <div className="ag-theme-alpine" style={{ height: 'calc(100vh - 178px)' }}>
                {data.length > 0 ?
                    <AgGridReact
                        rowData={data}
                        defaultColDef={defaultColDef}
                        ensureDomOrder={true}
                        enableCellTextSelection={true}
                        enableRangeSelection={true}
                    >
                        <AgGridColumn headerName="ID Subconsumo" field="idSubUsage"></AgGridColumn>
                        <AgGridColumn headerName="ID Consumo" field="idUsage"></AgGridColumn>
                        <AgGridColumn headerName="Data Subconsumo" field="idDay" filter="agDateColumnFilter" filterParams={dateFilterParams} valueFormatter={getDate}></AgGridColumn>
                        <AgGridColumn headerName="Energia Total Período" field="energia_total_periodo"></AgGridColumn>
                        <AgGridColumn headerName="Duração Período" field="periodDuration"></AgGridColumn>
                        <AgGridColumn headerName="Preço OPC Total" field="preco_opc"></AgGridColumn>
                        <AgGridColumn headerName="Preço OPC Ativação" field="preco_opc_ativacao" ></AgGridColumn>
                        <AgGridColumn headerName="Preço OPC Energia" field="preco_opc_energia" ></AgGridColumn>
                        <AgGridColumn headerName="Preço OPC Tempo" field="preco_opc_tempo"></AgGridColumn>
                        <AgGridColumn headerName="Preço Unitário - OPC Ativação" field="preco_unitario_opc_ativacao"></AgGridColumn>
                        <AgGridColumn headerName="Preço Unitário - OPC Energia" field="preco_unitario_opc_energia"></AgGridColumn>
                        <AgGridColumn headerName="Preço Unitário - OPC Tempo" field="preco_unitario_opc_tempo"></AgGridColumn>
                        <AgGridColumn headerName="Ficheiro Origem" field="filename"></AgGridColumn>
                        <AgGridColumn headerName="Data Criação Ficheiro" field="file_creation_date" filter="agDateColumnFilter" filterParams={dateFilterParams} valueFormatter={getDate}></AgGridColumn>
                        <AgGridColumn headerName="Data Recolha Informação" field="inserted_at" filter="agDateColumnFilter" filterParams={dateFilterParams} valueFormatter={getDate}></AgGridColumn>
                    </AgGridReact> :
                    <Loading />
                }
            </div>
        </Wrapper>
    )
}












