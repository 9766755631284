import React, { useState } from 'react'
import { useEffect } from 'react'
import { useContext } from 'react'
import { Route, useHistory, Switch } from 'react-router-dom'
import { ContentWrapper } from '../../../components/common/ContentWrapper'
import { SubContent } from '../../../components/common/SubContent'
import { SubHeader } from '../../../components/common/SubHeader'
import { PageContext } from '../../../contexts/PageContext'
import { SideTab } from './subPages/SideTab'
import Chargers from './chargers/Chargers'
import HomeCharging from './subPages/HomeCharging'
import TabsStripe from '../../../components/common/TabsStripe'

export default function Wallboxes({ match }) {
    const { page, setPage } = useContext(PageContext)
    const tabs = [{name: "Consumos", path: `${match.path}/usages`}, {name: "Postos", path: `${match.path}/chargers`}]
    const [pagination, setPagination] = useState({totalPages: 1, currentPage: 1})
    const [tab, setTab] = useState(0)
    
    useEffect(() => {
        setPage("wallboxes")
    }, [page])

    return (
        <ContentWrapper>
            <SubHeader title="Wall Boxes" pagination={pagination} setPagination={setPagination}>
                <TabsStripe tabState={[tab, setTab]} tabs={tabs} pagination={pagination} setPagination={setPagination}/>
                {/* <SideTab name={"Consumos"} onClick={() => history.push(`${match.url}/usages`)}></SideTab> */}
            </SubHeader>
            <SubContent>
                {/* <Switch>
                    <Route path={match.url + "/chargers"}><Chargers setTab={setTab} setPagination={setPagination} pagination={pagination}/></Route>
                    <Route path={match.url + "/usages"}><HomeCharging setTab={setTab} setPagination={setPagination} pagination={pagination}/></Route> 
                    <Route path={match.url + "/"}><HomeCharging setTab={setTab} setPagination={setPagination} pagination={pagination}/></Route> 
                </Switch> */}
                Work In Progress
            </SubContent>
        </ContentWrapper>
    )
}
