import React from 'react'
import styled from 'styled-components/macro'
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { useEffect } from 'react';
import axios from 'axios';
import { useState } from 'react';
import { useContext } from 'react';
import { AppContext } from '../../../../contexts/AppContext';
import Loading from '../../../../components/common/Loading';

const Wrapper = styled.div`
`

export default function CemeUsagesTable({ setTab, setPagination, pagination }) {

    const { appState, setAppState } = useContext(AppContext);
    const [data, setData] = useState([])

    // const apiUrl = process.env.REACT_APP_CEME_API_ENDPOINT;
    // const apiUrl = 'http://localhost:7070';
    const apiUrl = 'https://ceme-api-xeu74yuiwq-ez.a.run.app'

    useEffect(() => {
        setTab(0)
        try {
            getUsages(pagination.currentPage)
        } catch (e) {
            console.error(e)
        }
    }, [pagination.currentPage])

    const getUsages = async (currentPage) => {
        const options = {

        }
        const response = await axios.get(`${apiUrl}/api/consumptions/`, {
            headers: {
                authorization: appState.user.accessToken,
                refresh_token: appState.user.refreshToken
            },
            params: {
                page: currentPage - 1,
                size: 100
            }
        })
        if (response.headers.access_token && response.headers.refresh_token) {
            setAppState({ ...appState, user: { ...appState.user, accessToken: response.headers.access_token, refreshToken: response.headers.refresh_token } })
        }
        setData(response.data.consumptions)
        setPagination({ totalPages: response.data.totalPages, currentPage: currentPage })
    }



    const dateFilterParams = {
        // provide comparator function
        comparator: (filterLocalDateAtMidnight, cellValue) => {
            const dateAsString = cellValue;

            if (dateAsString == null) {
                return 0;
            }

            // In the example application, dates are stored as dd/mm/yyyy
            // We create a Date object for comparison against the filter date

            const cellDate = new Date(dateAsString);

            // Now that both parameters are Date objects, we can compare
            if (cellDate < filterLocalDateAtMidnight) {
                return -1;
            } else if (cellDate > filterLocalDateAtMidnight) {
                return 1;
            }
            return 0;
        }
    };

    const getDate = params => {
        const dateString = params.value
        const year = dateString.substr(0, 4)
        const month = dateString.substr(5, 2)
        const day = dateString.substr(8, 2)
        const hour = dateString.substr(11, 2)
        const min = dateString.substr(14, 2)
        const sec = dateString.substr(17, 2)

        // console.log(`${year}-${month}-${day} ${hour}:${min}:${sec}`)
        return `${year}-${month}-${day} ${hour}:${min}:${sec}`
    }

    const defaultColDef = {
        filter: true,
        resizable: true,
        sortable: true
    }

    const getkWh = params => {
        return `${params.value} kWh`
    }

    const getPrice = params => {
        return `€${params.value}`
    }


    return (
        <Wrapper>
            <div className="ag-theme-alpine" style={{ height: 'calc(100vh - 178px)' }}>
                {data.length > 0 ?
                    <AgGridReact
                        rowData={data}
                        defaultColDef={defaultColDef}
                        ensureDomOrder={true}
                        enableCellTextSelection={true}
                    >
                        <AgGridColumn maxWidth={400} headerName="Informação Base" field="idUsage">
                            <AgGridColumn maxWidth={400} headerName="ID Utilização" field="idUsage"></AgGridColumn>
                            <AgGridColumn maxWidth={400} headerName="ID Carregamento" field="idSubUsage"></AgGridColumn>
                            <AgGridColumn maxWidth={400} headerName="ID Cartão" field="idExternalNumber"></AgGridColumn>
                            <AgGridColumn maxWidth={400} headerName="Data Início" field="startTimestamp" filter="agDateColumnFilter" filterParams={dateFilterParams} valueFormatter={getDate}></AgGridColumn>
                            <AgGridColumn maxWidth={400} headerName="Data Fim" field="stopTimestamp" filter="agDateColumnFilter" filterParams={dateFilterParams} valueFormatter={getDate}></AgGridColumn>
                        </AgGridColumn>
                        <AgGridColumn maxWidth={400} headerName="Energia" field="idUsage" valueFormatter={getkWh}>
                            <AgGridColumn maxWidth={400} headerName="Energia Vazio" field="energia_vazio" valueFormatter={getkWh}></AgGridColumn>
                            <AgGridColumn maxWidth={400} headerName="Energia Fora Vazio" field="energia_fora_vazio" valueFormatter={getkWh}></AgGridColumn>
                            <AgGridColumn maxWidth={400} headerName="Energia Total" field="energia_total_periodo" valueFormatter={getkWh}></AgGridColumn>
                        </AgGridColumn>
                        <AgGridColumn maxWidth={400} headerName="Custos OPC" field="idUsage">
                            <AgGridColumn maxWidth={400} headerName="Preço OPC" field="preco_opc"></AgGridColumn>
                            <AgGridColumn maxWidth={400} headerName="Preço Unitário OPC Ativação" field="preco_unitario_opc_ativacao" valueFormatter={getPrice}></AgGridColumn>
                            <AgGridColumn maxWidth={400} headerName="Preço Unitário OPC Energia" field="preco_unitario_opc_energia" valueFormatter={getPrice}></AgGridColumn>
                            <AgGridColumn maxWidth={400} headerName="Preço Unitário OPC Tempo" field="preco_unitario_opc_tempo" valueFormatter={getPrice}></AgGridColumn>
                            <AgGridColumn maxWidth={400} headerName="Preço OPC Ativação" field="preco_opc_ativacao" valueFormatter={getPrice}></AgGridColumn>
                            <AgGridColumn maxWidth={400} headerName="Preço OPC Energia" field="preco_opc_energia" valueFormatter={getPrice}></AgGridColumn>
                            <AgGridColumn maxWidth={400} headerName="Preço OPC Tempo" field="preco_opc_tempo" valueFormatter={getPrice}></AgGridColumn>
                        </AgGridColumn>
                        <AgGridColumn maxWidth={400} headerName="Tarifa Acesso Redes " field="idUsage">
                            <AgGridColumn maxWidth={400} headerName="Preço Unitário Acesso Redes Vazio" field="preco_unitario_acesso_redes_vazio" valueFormatter={getPrice}></AgGridColumn>
                            <AgGridColumn maxWidth={400} headerName="Preço Unitário Acesso Redes Fora Vazio" field="preco_unitario_acesso_redes_fora_vazio" valueFormatter={getPrice}></AgGridColumn>
                            <AgGridColumn maxWidth={400} headerName="Desconto Unitário Acesso Redes Vazio" field="desconto_unitario_acesso_redes_vazio" valueFormatter={getPrice}></AgGridColumn>
                            <AgGridColumn maxWidth={400} headerName="Desconto Unitário Acesso Redes Fora Vazio" field="desconto_unitario_acesso_redes_fora_vazio" valueFormatter={getPrice}></AgGridColumn>
                            <AgGridColumn maxWidth={400} headerName="Preço Unitário c/ Desconto Acesso Redes Vazio" field="preco_unitario_com_desconto_acesso_redes_vazio" valueFormatter={getPrice}></AgGridColumn>
                            <AgGridColumn maxWidth={400} headerName="Preço Unitário c/ Desconto Acesso Redes Fora Vazio" field="preco_unitario_com_desconto_acesso_redes_fora_vazio" valueFormatter={getPrice}></AgGridColumn>
                            <AgGridColumn maxWidth={400} headerName="Desconto Acesso Redes Vazio" field="desconto_acesso_redes_vazio" valueFormatter={getPrice}></AgGridColumn>
                            <AgGridColumn maxWidth={400} headerName="Desconto Acesso Redes Fora Vazio" field="desconto_acesso_redes_fora_vazio" valueFormatter={getPrice}></AgGridColumn>
                            <AgGridColumn maxWidth={400} headerName="Preço Sem Desconto Acesso Redes Vazio" field="preco_sem_desconto_acesso_redes_vazio" valueFormatter={getPrice}></AgGridColumn>
                            <AgGridColumn maxWidth={400} headerName="Preço Sem Desconto Acesso Redes Fora Vazio" field="preco_sem_desconto_acesso_redes_fora_vazio" valueFormatter={getPrice}></AgGridColumn>
                            <AgGridColumn maxWidth={400} headerName="Preço Com Desconto Acesso Redes Vazio" field="preco_com_desconto_acesso_redes_vazio" valueFormatter={getPrice}></AgGridColumn>
                            <AgGridColumn maxWidth={400} headerName="Preço Com Desconto Acesso Redes Fora Vazio" field="preco_com_desconto_acesso_redes_fora_vazio" valueFormatter={getPrice}></AgGridColumn>
                            <AgGridColumn maxWidth={400} headerName="Desconto Acesso Redes" field="desconto_acesso_redes" valueFormatter={getPrice}></AgGridColumn>
                            <AgGridColumn maxWidth={400} headerName="Preço Sem Desconto Acesso Redes" field="preco_sem_desconto_acesso_redes" valueFormatter={getPrice}></AgGridColumn>
                            <AgGridColumn maxWidth={400} headerName="Preço Com Desconto Acesso Redes" field="preco_com_desconto_acesso_redes" valueFormatter={getPrice}></AgGridColumn>
                        </AgGridColumn>
                    </AgGridReact> :
                    <Loading />
                }
            </div>
        </Wrapper>
    )
}












