import React from 'react'
import styled from 'styled-components/macro'
import {Link} from 'react-router-dom'
import evazLogo from '../../assets/logo/logo.png'


const Frame = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    z-index: 1000;
    width: 100%;
    height: 50px;
    box-shadow: 0px 3px 4px rgb(0 0 0 / 10%);

    &::before {
        content: '';
        backdrop-filter: blur(4px);
        -webkit-backdrop-filter: blur(4px);
        background: rgba(255, 255, 255, 0.93);
        z-index: -999;
        width: 100%;
        height: 100%;
        top: 0;
        position: absolute;
    }
`

const ActionsContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width:100%;

    @media(max-width: 1000px) {
        width: auto;
    }
`

const LogoContainer = styled.div`
    align-items: center;
    display: flex;
    margin: 0 30px;
`

const Logo = styled.img`
    width: 100px;
    height: auto;
`


export default function Header() {
    return (
        <Frame>
            <LogoContainer>
                <Link smooth={"true"} to="/">
                    <Logo src={evazLogo} />
                </Link>
            </LogoContainer>
            <ActionsContainer>

            </ActionsContainer>
        </Frame>
    )
}
