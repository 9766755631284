import styled from "styled-components/macro";

const Wrapper = styled.div`
    width: auto;
    margin-left: 200px;
    background-color: #F8F8F8;
    height: calc(100vh - 50px);
    padding-top: 50px;
`;

const Content = styled.div`
    padding: 25px;
    height: -webkit-fill-available;
`

export const ContentWrapper = ({children}) => {
    return <Wrapper>
        <Content>
            {children}
        </Content>
    </Wrapper>
}