import React from 'react'

import {BrowserRouter, Route, Switch, Redirect} from "react-router-dom";
import Header from '../header/Header'
import Sidebar from '../sidebar/Sidebar';
import Opc from '../public/opc/Opc';
import Wallboxes from '../private/homecharging/Wallboxes';
import Users from '../admin/users/Users';
import Ceme from '../public/ceme/Ceme';


export default function Router() {
    return (
        <BrowserRouter>
            <Header />
            <Sidebar />
            <Switch>
                <Route path="/privado/wallboxes" component={Wallboxes}/>
                <Route path="/publico/opc"  component={Opc}/>
                <Route path="/publico/ceme" component={Ceme}/>
                <Route path="/admin/users" component={Users}/>
                <Route exact path="/">
                    <Redirect to="/publico/opc" /> 
                </Route>
            </Switch>
        </BrowserRouter>
    )
}
