import React, { useContext } from 'react'
import { AppContext } from '../../contexts/AppContext'
import Login from '../login/Login'



export default function Main({children}) {
    const {appState} = useContext(AppContext)

    return (
        <div>
            {appState.user ? children : <Login />}
        </div>
    )
}
