import React, { useState } from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';


export default function TabsStripe(props) {
  const history = useHistory()
  const [tab, setTab] = props.tabState

  const handleChange = (event, newValue) => {
    props.setPagination({...props.pagination, currentPage: 1})
    setTab(newValue)
    history.push(props.tabs[newValue].path)
  };

  return (
      <Tabs
        TabIndicatorProps={{style: {background:'#A5D65D'}}}
        value={tab}
        onChange={handleChange}
        centered
      >
        {props.tabs.map( tab => <Tab key={tab.name} label={tab.name} /> )}
      </Tabs>
  );
}