import React, { useContext } from 'react'
import { useState } from 'react';
import styled from 'styled-components/macro';
import Loading from '../../components/common/Loading';
import Input from '../../components/common/Input';
import { AppContext } from '../../contexts/AppContext';
import axios from 'axios';
import InputButton from '../../components/common/InputButton';

const Container = styled.div`
    position: relative;
    top: 20vh;
`

const Form = styled.form`
    display: flex;
    margin: 0 auto;
    flex-direction: column;
    max-width: 600px;
    height: 400px;
    justify-content: space-between;
    align-items: center;
`

const InputTitle = styled.div`
    font-size: 12pt;
    color: #919191;
    margin-bottom: 7px;
`

const InputGroup = styled.div`
    margin-bottom: 30px;
`

const Inputs = styled.div`
    margin-bottom: 20px;
`

const Title = styled.h2`
    color: #919191;
    margin-bottom: 50px;
`



export default function Login() {

    const { appState, setAppState } = useContext(AppContext);
    const [loading, setLoading] = useState(false)
    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")

    const submit = async e => {

        e.preventDefault();
        const apiUrl = process.env.REACT_APP_WEBAPI_ENDPOINT;
        // const apiUrl = 'http://localhost:5000';

        setLoading(true)
        try {
            const response = await axios.post(`${apiUrl}/auth/login`, {
                email: username, 
                password: password
            })
            // navigate("/account")
            console.log("success");
            setAppState({...appState, user: response.data})
            

        } catch (e) {
            console.error("Login failed")
        }
        setLoading(false)
    }

    return (
        
            <Container>
                <Form>
                    <Title>{'Área de Cliente'.toUpperCase()}</Title>
                    <Inputs>
                        <InputGroup>
                            <InputTitle>Email</InputTitle>
                            <Input type={"email"} title={"email"} value={username} onChange={
                                e => setUsername(e.target.value)
                            }></Input>
                        </InputGroup>

                        <InputGroup>
                            <InputTitle>Password</InputTitle>
                            <Input type={"password"} title={"pass"} value={password} onChange={e => setPassword(e.target.value)
                            }></Input>
                        </InputGroup>
                    </Inputs>
                    {loading ? <Loading /> :<InputButton type={"submit"} onClick={e => submit(e)} value="Entrar"></InputButton> }
                </Form>
            </Container>
    )
}
