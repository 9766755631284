import { ListItemIcon } from '@material-ui/core'
import React, { useContext, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import styled from 'styled-components/macro'
import { AppContext } from '../../contexts/AppContext'
import { PageContext } from '../../contexts/PageContext'


const ListItemIcon_ = styled(ListItemIcon)`
    min-width: 40px;
`

const Container = styled.div`
    position: fixed;
    width: 200px;
    height: calc(100% - 50px);
    left: 0;
    margin-top: 50px;
    background-color: #292929;
    padding-top: 30px;
`

const NavGroup = styled.div`
    padding: 0 20px 40px 20px;
    text-transform: uppercase;
`;

const NavGroupTitle = styled.div`
    font-size: 8pt;
    font-weight: 600;
    margin-bottom: 15px;
    padding-left: 15px;
    color: #C5C5C5;
`;

const NavItem = styled.div`
    font-size: 10pt;
    padding: 15px 0 15px 15px;
    transition: 0.2s;
    color: ${props => props.page === props.identifier ? '#93e015' : 'white'};

    &:hover {
        cursor: pointer;
        color: #93e015;
    }
`;

export default function Sidebar() {

    const { page, setPage } = useContext(PageContext)
    const { appState } = useContext(AppContext)

    return (
        <Container>
            {/* <ListItemIcon_><Home className="sidebar-list-icon" /></ListItemIcon_> */}
            <NavGroup>
                <NavGroupTitle>Público</NavGroupTitle>
                <Link to={"/publico/ceme"}><NavItem page={page} identifier={"ceme"}>CEME</NavItem></Link>
                <Link to={"/publico/opc"}><NavItem page={page} identifier={"opc"}>OPC</NavItem></Link>
            </NavGroup>
            <NavGroup>
                <NavGroupTitle>Privado</NavGroupTitle>
                <Link to={"/privado/wallboxes"}><NavItem page={page} identifier={"wallboxes"}>Wall Boxes</NavItem></Link>
            </NavGroup>
            <NavGroup>
                <NavGroupTitle>Admin</NavGroupTitle>
                <Link to={"/admin/users"}><NavItem page={page} identifier={"users"}>Utilizadores</NavItem></Link>
            </NavGroup>
        </Container>
    )
}
