import React, { useState } from 'react'
import { AppContext } from './contexts/AppContext'
import styled from 'styled-components/macro'
import Main from './containers/main/Main'
import Router from './containers/router/Router'
import { PageContext } from './contexts/PageContext'
import './App.css'



const Body = styled.div`
  background-color: white;
`

const defaultAppState = {
  user: null
}

function App() {
  const [appState, setAppState] = useState(defaultAppState)
  const [page, setPage] = useState("OPC")

  return (
    <AppContext.Provider value={{ appState, setAppState }}>
      <PageContext.Provider value={{ page, setPage }}>
        <Body>
          <Main>
            <Router />
          </Main>
        </Body>
      </PageContext.Provider>
    </AppContext.Provider>
  );
}

export default App;
