import React, { useState } from 'react'
import { Button } from '@material-ui/core'
import styled from 'styled-components/macro'


const TabContainer = styled.div`
        position: relative;
        height: -webkit-fill-available;
    `

const MenuTab = styled(Button)`
        height: -webkit-fill-available;
        width: 100%;
        padding: 0 40px !important;
        white-space: nowrap;
        color: #919191 !important;
        background-color: ${props => props.open ? "#F9F9F9" : "white"} !important;
        &:hover {
            transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
            background-color: #A5D65D;
        }
    `


export const SideTab = ({ name, Menu, onClick }) => {
    
    const [open, setOpen] = useState(false)
    // const open = Boolean(anchorEl);

    return (
        <TabContainer
            onMouseEnter={() => setOpen(true)}
            onMouseLeave={() => setOpen(false)}
            onClick={onClick ?? onClick}
        >
            <MenuTab
                aria-owns={open ? 'mouse-over-popover-' + name : undefined}
                aria-haspopup="true" 
                open={open}
            >
                {name}
            </MenuTab>
            {Menu && <Menu open={open} name={name} />}
        </TabContainer>
    )
}
