import React, { useState } from 'react'
import { useEffect } from 'react'
import { useContext } from 'react'
import { Route, useHistory } from 'react-router-dom'
import { ContentWrapper } from '../../../components/common/ContentWrapper'
import { SubContent } from '../../../components/common/SubContent'
import { SubHeader } from '../../../components/common/SubHeader'
import TabsStripe from '../../../components/common/TabsStripe'
import { PageContext } from '../../../contexts/PageContext'
import UserList from './subPages/UserList'

export default function Users({ match }) {
    const {page, setPage} = useContext(PageContext)
    const [tab, setTab] = useState(0)
    const [pagination, setPagination] = useState({totalPages: 1, currentPage: 1})
    const tabs = [{name: "Lista", path: `${match.path}/list`}]
    
    useEffect(() => {
        setPage("users")
    }, [page])

    return (
        <ContentWrapper>
            <SubHeader title="Utilizadores" pagination={pagination} setPagination={setPagination}>
                <TabsStripe tabState={[tab, setTab]} tabs={tabs} pagination={pagination} setPagination={setPagination}/>
            </SubHeader>
            <SubContent>
                {/* <Route exact path={match.url + "/list"} component={UserList} />
                <Route exact path={match.url + "/"} component={UserList} /> */}
                Work In Progress
            </SubContent>
        </ContentWrapper>
    )
}
