import React, { useState } from 'react'
import { useEffect } from 'react'
import { useContext } from 'react'
import { Route, useHistory } from 'react-router'
import { ContentWrapper } from '../../../components/common/ContentWrapper'
import { SubContent } from '../../../components/common/SubContent'
import { SubHeader } from '../../../components/common/SubHeader'
import TabsStripe from '../../../components/common/TabsStripe'
import { PageContext } from '../../../contexts/PageContext'
import { SideTab } from '../../private/homecharging/subPages/SideTab'
import OpcUsagesTable from './sub/OpcUsagesTable'
import OpcSubUsagesTable from './sub/OpcSubUsagesTable'
import OpcDailyTable from './sub/OpcDailyTable'
import OpcMonthlyTable from './sub/OpcMonthlyTable'


export default function Opc({ match }) {
    const { page, setPage } = useContext(PageContext)
    const [tab, setTab] = useState(0)
    const [pagination, setPagination] = useState({totalPages: 1, currentPage: 1})
    const tabs = [
        {
            name: "Consumos",
            path: `${match.path}/usages`
        },
        {
            name: "Subconsumos",
            path: `${match.path}/subusages`
        },
        {
            name: "Diário",
            path: `${match.path}/daily`
        },
        {
            name: "Mensal",
            path: `${match.path}/monthly`
        },
    ]

    useEffect(() => {
        setPage("opc")
    }, [page])

    return (
        <ContentWrapper>
            <SubHeader title="OPC" pagination={pagination} setPagination={setPagination}>
                <TabsStripe tabState={[tab, setTab]} tabs={tabs} pagination={pagination} setPagination={setPagination}/>
                {/* <SideTab name={"Consumos"} onClick={() => history.push(`${match.url}/usages`)}></SideTab> */}
            </SubHeader>
            <SubContent>
                <Route path={match.url + "/subusages"}><OpcSubUsagesTable setTab={setTab} setPagination={setPagination} pagination={pagination}/></Route>
                <Route path={match.url + "/usages"}><OpcUsagesTable setTab={setTab} setPagination={setPagination} pagination={pagination}/></Route>
                <Route path={match.url + "/daily"}><OpcDailyTable setTab={setTab} setPagination={setPagination} pagination={pagination}/></Route>
                <Route path={match.url + "/monthly"}><OpcMonthlyTable setTab={setTab} setPagination={setPagination} pagination={pagination}/></Route>
                <Route exact path={match.url + "/"}><OpcUsagesTable setTab={setTab} setPagination={setPagination} pagination={pagination}/></Route>
            </SubContent>
        </ContentWrapper>
    )
}