import React, { useState } from 'react'
import { useEffect } from 'react'
import { useContext } from 'react'
import { Route, useHistory } from 'react-router'
import { ContentWrapper } from '../../../components/common/ContentWrapper'
import { SubContent } from '../../../components/common/SubContent'
import { SubHeader } from '../../../components/common/SubHeader'
import TabsStripe from '../../../components/common/TabsStripe'
import { PageContext } from '../../../contexts/PageContext'
import CemeUsagesTable from './sub/CemeUsagesTable'

export default function Opc({ match }) {
    const {page, setPage} = useContext(PageContext)
    const [tab, setTab] = useState(0)
    const [pagination, setPagination] = useState({totalPages: 1, currentPage: 1})
    const tabs = [{name: "Consumos", path: `${match.path}/usages`}]

    useEffect(() => {
        setPage("ceme")
    }, [page])

    return (
        <ContentWrapper>
            <SubHeader title="CEME" pagination={pagination} setPagination={setPagination}>
                <TabsStripe tabState={[tab, setTab]} tabs={tabs} pagination={pagination} setPagination={setPagination}/>
            </SubHeader>
            <SubContent>
                {/* <Route path={match.url + "/usages"}><CemeUsagesTable setTab={setTab} pagination={pagination} setPagination={setPagination}/></Route>
                <Route path={match.url + "/"}><CemeUsagesTable setTab={setTab} pagination={pagination} setPagination={setPagination}/></Route>  */}
                Work in Progress
            </SubContent>
        </ContentWrapper>
    )
}