import React from 'react'
import { useState, useContext } from 'react';
import styled from 'styled-components/macro';
import Grid from './Grid';
import { AppContext } from '../../../../contexts/AppContext';
import { PageContext } from '../../../../contexts/PageContext';
import { useEffect } from 'react';

const Container = styled.div`
`

const GridContainer = styled.div`
`

const TabContainer = styled.div`
    height: 70px;
    width: 100%;
`

export default function HomeCharging() {
    const { appState } = useContext(AppContext);
    const { page, setPage } = useContext(PageContext)

    useEffect(() => {
        setPage("wallboxes")
    }, [page])


    return (
        <>
            {appState.user ?
                <Container>
                    <GridContainer>
                        <Grid />
                    </GridContainer>
                </Container> : "Não está ligado, por favor volte a tentar entrar com a sua conta"}
        </>
    )
}
